import { createContext, useMemo, useContext } from 'react';
import type { PropsWithChildren } from 'react';
import { getPriceTier } from '.';

export type AppContext = {
   ifixitOrigin: string;
   adminMessage?: string;
   priceTiers: Record<string, string>;
};

const AppContext = createContext<AppContext | null>(null);

const { Provider } = AppContext;

type AppProviderProps = PropsWithChildren<AppContext>;

export function AppProvider({
   ifixitOrigin,
   adminMessage,
   priceTiers,
   children,
}: AppProviderProps) {
   const value = useMemo(
      (): AppContext => ({
         ifixitOrigin,
         adminMessage,
         priceTiers,
      }),
      [ifixitOrigin, adminMessage, priceTiers]
   );

   return <Provider value={value}>{children}</Provider>;
}

export function useAppContext() {
   const context = useContext(AppContext);
   if (context === null) {
      // eslint-disable-next-line @ifixit/no-new-error
      throw new Error('useAppContext must be used within a AppProvider');
   }
   return context;
}

export function usePriceTier({ storeCode }: { storeCode: string }) {
   const { priceTiers } = useAppContext();
   return getPriceTier({ priceTiers, storeCode });
}
