import { type ScopeContext, SentryError } from '@ifixit/sentry';
import { STOREFRONT_API_VERSION } from './env';
import { getSdk, type Requester } from './generated/sdk';

export type ShopCredentials = {
   shopDomain: string;
   storefrontAccessToken: string;
};

export function getClientShopifyStorefrontSdk({
   shopDomain,
   storefrontAccessToken,
}: ShopCredentials) {
   const requester: Requester = async <R, V>(doc: string, variables: V): Promise<R> => {
      const body = {
         query: doc,
         variables,
      };
      const response = await fetch(
         `https://${shopDomain}/api/${STOREFRONT_API_VERSION}/graphql.json`,
         {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'X-Shopify-Storefront-Access-Token': storefrontAccessToken,
            },
            body: JSON.stringify(body),
         }
      );
      const result = await getResult<R>(response);
      if (result.errors && result.errors.length > 0) {
         let errorMessage = 'Storefront API query failed with errors: ';
         const errorMessages = result.errors.map(error => {
            const code = error.extensions?.code ?? 'UNKNOWN';
            return `[code: ${code}]: ${error.message}`;
         });
         errorMessage +=
            errorMessages.length > 1 ? errorMessages.join('\n\t - ') : errorMessages[0];
         console.error(errorMessage);
         const sentryDetails: Partial<ScopeContext> = {
            contexts: {
               graphql_response: result,
               body,
            },
            tags: {
               request_url: response.url,
               request_status: response.status.toString(),
               request_status_text: response.statusText,
            },
         };
         throw new SentryError(errorMessage, sentryDetails);
      }
      return result.data;
   };

   return getSdk(requester);
}

type ApiResult<R> = {
   data: R;
   errors?: Array<{
      message: string | null;
      path?: string[] | null;
      extensions?: {
         code: string | null;
      };
   }> | null;
};

async function getResult<R>(response: Response): Promise<ApiResult<R>> {
   if (!response.ok) {
      throw new SentryError(`GraphQL query failed to execute: ${response.statusText}`);
   }
   return response.json();
}
