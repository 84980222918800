import type { User } from '@ifixit/auth-sdk';
import { captureException, SentryError } from '@ifixit/sentry';
import type { CustomerAccessToken } from '@ifixit/shopify-storefront-client';
import { safeLocalStorage } from '@ifixit/utils';

export function getStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): CustomerAccessToken | null {
   const STORAGE_KEY = customerAccessTokenStorageKey(user.id, storeCode);
   try {
      return safeLocalStorage.getJson(STORAGE_KEY);
   } catch (error) {
      console.error(error);
      captureException(
         new SentryError('Failed to parse stored customer access token json', { extra: { error } })
      );
      return null;
   }
}

export function setStoredCustomerAccessToken({
   storeCode,
   customerAccessToken,
   user,
}: { storeCode: string; customerAccessToken: CustomerAccessToken; user: User }): void {
   const STORAGE_KEY = customerAccessTokenStorageKey(user.id, storeCode);
   safeLocalStorage.setJson(STORAGE_KEY, customerAccessToken);
}

export function clearStoredCustomerAccessToken({
   storeCode,
   user,
}: { storeCode: string; user: User }): void {
   const STORAGE_KEY = customerAccessTokenStorageKey(user.id, storeCode);
   safeLocalStorage.removeItem(STORAGE_KEY);
}

function customerAccessTokenStorageKey(userId: number, storeCode: string) {
   return `shopify.${storeCode}.user.${userId}.customerAccessToken`;
}

export function getStoredCartId({
   user,
   storeCode,
}: { user: User | null; storeCode: string }): string | null {
   const STORAGE_KEY = cartIdStorageKey(user?.id ?? null, storeCode);
   const storedCartId = safeLocalStorage.getItem(STORAGE_KEY);
   return storedCartId;
}

export function setStoredCartId({
   storeCode,
   cartId,
   user,
}: { storeCode: string; cartId: string; user: User | null }): void {
   const STORAGE_KEY = cartIdStorageKey(user?.id ?? null, storeCode);
   safeLocalStorage.setItem(STORAGE_KEY, cartId);
}

export function clearStoredCartId({
   storeCode,
   user,
}: { storeCode: string; user: User | null }): void {
   const STORAGE_KEY = cartIdStorageKey(user?.id ?? null, storeCode);
   safeLocalStorage.removeItem(STORAGE_KEY);
}

function cartIdStorageKey(userId: number | null, storeCode: string) {
   return `shopify.${storeCode}.user.${userId ?? 'anonymous'}.cartId`;
}
