import { useAuthenticatedUser, type User } from '@ifixit/auth-sdk';
import { SentryError } from '@ifixit/sentry';
import {
   type StorefrontClient,
   useShopifyStorefrontClient,
} from '@ifixit/shopify-storefront-client';
import { useIsMutating, useQuery } from '@tanstack/react-query';
import { performAddToCart } from './use-add-to-cart';
import { useGetCustomerAccessToken } from '../use-get-customer-access-token';
import {
   clearStoredCartId,
   clearStoredCustomerAccessToken,
   getStoredCartId,
   setStoredCartId,
} from '../../helpers/storage';
import { getCart } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import { cartKeys } from '../../utils';

export function useCart() {
   const userQuery = useAuthenticatedUser();
   const getCustomerAccessToken = useGetCustomerAccessToken();
   const { client, currencyCode: fallbackCurrencyCode, storeCode } = useShopifyStorefrontClient();
   const isMutating = useIsMutating();

   const query = useQuery({
      queryKey: cartKeys.cart,
      enabled: !isMutating && !userQuery.isPlaceholderData,
      queryFn: async () => {
         const user = userQuery?.data ?? null;
         const cartId = getStoredCartId({ user, storeCode });
         const anonymousCartId = getStoredCartId({ user: null, storeCode });
         const existingCart = cartId ? await getCart(client, { cartId }) : null;
         const anonymousCart = anonymousCartId
            ? await getCart(client, { cartId: anonymousCartId })
            : null;
         if (anonymousCart && user) {
            const mergedCart = existingCart
               ? (await performAddToCart({
                    cartId: existingCart.id,
                    client,
                    lines: buildIfixitCart({ cart: anonymousCart, fallbackCurrencyCode }).lineItems,
                 })) ?? existingCart
               : await updateCartBuyerIdentity({
                    client,
                    cartId: anonymousCart.id,
                    getCustomerAccessToken,
                    storeCode,
                    user,
                 });
            setStoredCartId({ user, storeCode, cartId: mergedCart.id });
            clearStoredCartId({ user: null, storeCode });
            return buildIfixitCart({ cart: mergedCart, fallbackCurrencyCode });
         }
         return buildIfixitCart({
            cart: existingCart,
            fallbackCurrencyCode,
         });
      },
   });
   return query;
}

async function updateCartBuyerIdentity({
   client,
   cartId,
   getCustomerAccessToken,
   storeCode,
   user,
}: {
   client: StorefrontClient;
   cartId: string;
   getCustomerAccessToken: ReturnType<typeof useGetCustomerAccessToken>;
   storeCode: string;
   user: User;
}) {
   const customerAccessToken = await getCustomerAccessToken(user);
   let response = await client.updateCartBuyerIdentity({
      cartId,
      buyerIdentity: { customerAccessToken: customerAccessToken.accessToken },
   });
   if (
      response.cartBuyerIdentityUpdate?.cart &&
      response.cartBuyerIdentityUpdate.cart.buyerIdentity.customer == null
   ) {
      // If the token is invalid or expired, clear it from storage and retry.
      clearStoredCustomerAccessToken({ storeCode, user });
      const customerAccessToken = await getCustomerAccessToken(user);
      response = await client.updateCartBuyerIdentity({
         cartId,
         buyerIdentity: { customerAccessToken: customerAccessToken.accessToken },
      });
   }
   if (response.cartBuyerIdentityUpdate && response.cartBuyerIdentityUpdate.userErrors.length > 0) {
      console.error(response.cartBuyerIdentityUpdate.userErrors);
      throw new SentryError('User errors when updating cart buyer identity', {
         extra: { cartId, userErrors: response.cartBuyerIdentityUpdate.userErrors, user },
      });
   }
   if (!response.cartBuyerIdentityUpdate?.cart) {
      throw new SentryError('Failed to update cart buyer identity', { extra: { cartId, user } });
   }
   return response.cartBuyerIdentityUpdate.cart;
}
