import { IFixitAPIClient } from '@ifixit/ifixit-api-client';
import { captureMessage } from '@ifixit/sentry';
import { safeLocalStorage } from '@ifixit/utils';
import { z } from 'zod';

export type User = z.infer<typeof AuthenticatedUserSchema>;

export const userKeys = {
   user: ['user'],
};

export const userDataLocalKey = 'user.data.2';

export const AuthenticatedUserSchema = z.object({
   id: z.number(),
   username: z.string(),
   handle: z.string().nullable(),
   thumbnail: z.string().nullable(),
   algoliaApiKeyProducts: z.string().nullable(),
   priceTiers: z.record(z.string()),
   isAdmin: z.boolean(),
   teams: z.array(z.number()),
   links: z.record(z.any()),
   langid: z.string().optional().nullable(),
   unread_notification_count: z.number().optional().nullable(),
   privileges: z.array(z.string()),
   greatest_privilege: z.string().optional(),
   isEmployee: z.boolean(),
   isExemptFromAB: z.boolean(),
   preferredStoreDomain: z.string().optional().nullable(),
});

const UserApiResponseSchema = z.object({
   userid: z.number(),
   username: z.string(),
   unique_username: z.string().optional().nullable(),
   image: z
      .object({
         thumbnail: z.string().optional().nullable(),
      })
      .optional()
      .nullable(),
   algoliaApiKeyProducts: z.string().optional().nullable(),
   priceTiers: z.record(z.string()),
   privileges: z.array(z.string()),
   teams: z.array(z.number()),
   links: z.record(z.any()),
   langid: z.string().optional().nullable(),
   is_employee: z.boolean().optional(),
   unread_notification_count: z.number().optional().nullable(),
   isExemptFromAB: z.boolean(),
   preferredStoreDomain: z.string().optional().nullable(),
});

export async function fetchAuthenticatedUser(
   apiClient: IFixitAPIClient,
   init?: RequestInit
): Promise<User | null> {
   const response = await apiClient.getRaw('user', 'user', init);
   if (response.status === 401 || response.status === 403) {
      return null;
   } else if (!response.ok) {
      apiClient.throwFetchError(response, {
         input: 'user',
         init,
      });
   }
   const payload = await response.json();
   if (payload == null) {
      return null;
   }
   const userSchema = UserApiResponseSchema.safeParse(payload);
   if (!userSchema.success) {
      captureMessage('User schema parsing failed', {
         level: 'debug',
         extra: {
            schema: userSchema,
            payload: payload,
            error: userSchema.error,
         },
      });
      return null;
   }
   const isAdmin = userSchema.data.privileges.includes('Admin');
   const isEmployee = userSchema.data?.is_employee ?? false;
   return {
      id: userSchema.data.userid,
      username: userSchema.data.username,
      handle: userSchema.data.unique_username ?? null,
      thumbnail: userSchema.data.image?.thumbnail ?? null,
      algoliaApiKeyProducts: userSchema.data.algoliaApiKeyProducts ?? null,
      priceTiers:
         (isEmployee && makePriceTiers(getPriceTierOverride())) || userSchema.data.priceTiers,
      isAdmin: isAdmin,
      teams: userSchema.data.teams,
      links: userSchema.data.links,
      langid: userSchema.data.langid ?? null,
      unread_notification_count: userSchema.data.unread_notification_count,
      privileges: userSchema.data.privileges,
      isEmployee: isEmployee,
      isExemptFromAB: userSchema.data?.isExemptFromAB ?? false,
      preferredStoreDomain: userSchema.data?.preferredStoreDomain ?? null,
   };
}

const PRICE_TIER_OVERRIDE_KEY = 'user.price_tier.override';

export function getPriceTierOverride(): string | null {
   return safeLocalStorage.getItem(PRICE_TIER_OVERRIDE_KEY);
}

export function setPriceTierOverride(priceTier: string | null) {
   if (priceTier) {
      safeLocalStorage.setItem(PRICE_TIER_OVERRIDE_KEY, priceTier);
   } else {
      safeLocalStorage.removeItem(PRICE_TIER_OVERRIDE_KEY);
   }
}

export function makePriceTiers(priceTier: string | null) {
   return priceTier
      ? {
           au: priceTier,
           ca: priceTier,
           de: priceTier,
           dev: priceTier,
           eu: priceTier,
           eu_test: priceTier,
           fr: priceTier,
           test: priceTier,
           uk: priceTier,
           us: priceTier,
        }
      : null;
}
