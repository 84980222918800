import { SentryError } from '@ifixit/sentry';
import type {
   AddCartLinesMutation,
   AddCartLinesMutationVariables,
   GetCartQueryVariables,
   RemoveCartLinesMutationVariables,
   StorefrontClient,
   UpdateCartLinesMutationVariables,
} from '@ifixit/shopify-storefront-client';

export async function getCart(client: StorefrontClient, { cartId }: GetCartQueryVariables) {
   const { cart } = await client.getCart({ cartId });
   return cart ?? null;
}

export async function addToCart(
   client: StorefrontClient,
   { cartId, lines }: AddCartLinesMutationVariables
) {
   const { cartLinesAdd } = await client.addCartLines({ cartId, lines });
   if (cartLinesAdd && cartLinesAdd.userErrors.length > 0) {
      console.error(cartLinesAdd.userErrors);
      const validationErrors = getValidationErrors(cartLinesAdd.userErrors);
      throw new SentryError('User errors when adding to cart', {
         extra: { cartId, lines, userErrors: cartLinesAdd.userErrors, validationErrors },
      });
   }
   if (!cartLinesAdd?.cart) {
      throw new SentryError('Failed to add to cart', { extra: { cartId, lines } });
   }
   return cartLinesAdd.cart;
}

export async function updateCartLines(
   client: StorefrontClient,
   { cartId, lines }: UpdateCartLinesMutationVariables
) {
   const { cartLinesUpdate } = await client.updateCartLines({ cartId, lines });
   if (cartLinesUpdate && cartLinesUpdate.userErrors.length > 0) {
      console.error(cartLinesUpdate.userErrors);
      const validationErrors = getValidationErrors(cartLinesUpdate.userErrors);
      throw new SentryError('User errors when updating cart lines', {
         extra: { cartId, lines, userErrors: cartLinesUpdate.userErrors, validationErrors },
      });
   }
   if (!cartLinesUpdate?.cart) {
      throw new SentryError('Failed to update cart lines', { extra: { cartId, lines } });
   }
   return cartLinesUpdate.cart;
}

export async function removeCartLines(
   client: StorefrontClient,
   { cartId, lineIds }: RemoveCartLinesMutationVariables
) {
   const { cartLinesRemove } = await client.removeCartLines({ cartId, lineIds });
   if (cartLinesRemove && cartLinesRemove.userErrors.length > 0) {
      console.error(cartLinesRemove.userErrors);
      const validationErrors = getValidationErrors(cartLinesRemove.userErrors);
      throw new SentryError('User errors when removing cart lines', {
         extra: { cartId, lineIds, userErrors: cartLinesRemove.userErrors, validationErrors },
      });
   }
   if (!cartLinesRemove?.cart) {
      throw new SentryError('Failed to remove cart lines', { extra: { cartId, lineIds } });
   }
   return cartLinesRemove.cart;
}

type CartUserErrors = NonNullable<AddCartLinesMutation['cartLinesAdd']>['userErrors'];

export function getValidationErrors(userErrors: CartUserErrors) {
   return userErrors
      .filter(error => error.code === 'VALIDATION_CUSTOM')
      .map(error => error.message);
}
