'use client';

import { useAppContext } from '@ifixit/app/hooks';
import { useMemo } from 'react';
import { IFixitAPIClient } from '.';

/**
 * Get the iFixit API client.
 */
export function useIFixitApiClient() {
   const appContext = useAppContext();

   const client = useMemo(
      () =>
         new IFixitAPIClient({
            origin: appContext.ifixitOrigin,
         }),
      [appContext.ifixitOrigin]
   );

   return client;
}
