import { safeLocalStorage } from '@ifixit/utils';
import { useEffect, useState } from 'react';

/**
 * A simple hook to store user preferences in local storage.
 * @param key localStorage key
 * @param defaultData
 * @returns [value, setValue] tuple where `value` is the current value and `setValue` is a function to update it.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useLocalPreference<Data = any>(
   key: string,
   defaultData: Data,
   // eslint-disable-next-line @typescript-eslint/no-explicit-any
   validator: (data: any) => Data | null
): [Data, (data: Data) => void] {
   const [data, setData] = useState(defaultData);

   useEffect(() => {
      const serializedData = safeLocalStorage.getItem(key);
      if (serializedData != null) {
         try {
            const parsedData = validator(JSON.parse(serializedData));
            if (parsedData !== null) {
               setData(parsedData);
            }
         } catch {
            safeLocalStorage.removeItem(key);
         }
      }
   }, []);

   const setAndSave = (data: Data) => {
      setData(data);
      const serializedData = JSON.stringify(data);
      safeLocalStorage.setItem(key, serializedData);
   };

   return [data, setAndSave];
}
